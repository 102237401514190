import React, { useEffect } from "react";

import { Button, Grid } from "@mui/material";

import { UndoAppSnackbarMessageStyled } from "@/styles/components/common";
import { createUUID } from "@/utils/common-utils";

const { undoButtonStyled } = UndoAppSnackbarMessageStyled;

type Props = {
  messageId: string;
  message: string;
  onClick: () => void;
};

const UndoAppSnackbarMessage: React.FC<Props> = (props) => {
  const { messageId, message, onClick } = props;

  const closeFieldId = `cf_${createUUID()}`;

  useEffect(() => {
    setTimeout(() => {
      const messageElem = document.querySelector(`div[id="${messageId}"]`);
      const closeFieldElem = messageElem?.querySelector(`div[id="${closeFieldId}"]`);
      const closeBtnElem = messageElem?.querySelector(`button[class*="Toastify__close"]`);
      if (closeFieldElem && closeBtnElem) {
        closeFieldElem.appendChild(closeBtnElem);
      }
    }, 1);
  }, []);

  return (
    <Grid container direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
      <Grid item xs>
        {message}
      </Grid>
      <Grid item id={closeFieldId}></Grid>
      <Grid item xs={12} />
      <Grid item pt={1}>
        <Button variant="outlined" size={"small"} onClick={onClick} css={undoButtonStyled}>
          元に戻す
        </Button>
      </Grid>
    </Grid>
  );
};

export default React.memo(UndoAppSnackbarMessage);
