export const AUTH_INDEX_PATH = "/auth";
export const AUTH_SIGNUP_PATH = `${AUTH_INDEX_PATH}/signup`;
export const AUTH_SETUP_PATH = `${AUTH_INDEX_PATH}/setup`;
export const AUTH_FORGOT_PASS_PAGE = `${AUTH_INDEX_PATH}/reset-password`;

export const TASK_INDEX_PATH = "/task";
export const MY_TASK_PATH = `${TASK_INDEX_PATH}/me`; // パス情報に、typeを含む
export const GROUP_TASK_PATH = `${TASK_INDEX_PATH}/group`; // パス情報に、idとtypeを含む
export const MGMT_TASK_PATH = `${TASK_INDEX_PATH}/management`; // パス情報に、typeを含む
export const PUBLIC_TASK_PATH = `${TASK_INDEX_PATH}/public`; // パス情報に、typeを含む
export const PROJECT_TASK_PATH = `${TASK_INDEX_PATH}/project`; // パス情報に、idとtypeを含む

export const ORG_INDEX_PATH = "/organization";
export const ORG_STAFF_PATH = `${ORG_INDEX_PATH}/staff`; // パス情報に、typeを含む
export const ORG_DEFINE_PATH = `${ORG_INDEX_PATH}/definition`;
export const ORG_DEFINE_LAYER_PATH = `${ORG_DEFINE_PATH}/layer`;
export const ORG_DEFINE_BASE_NAME_PATH = `${ORG_DEFINE_PATH}/base-name`;
export const ORG_DEFINE_POSITION_PATH = `${ORG_DEFINE_PATH}/position`;
export const ORG_DEFINE_GROUP_PATH = `${ORG_DEFINE_PATH}/group`;
export const ORG_DEFINE_SUMMARY_PATH = `${ORG_DEFINE_PATH}/summary`;
export const ORG_PROJECT_PATH = `${ORG_INDEX_PATH}/project`;
export const ORG_AUTHORITY_RULES_PATH = `${ORG_INDEX_PATH}/authority-rules`;
export const ORG_CANVAS_PATH = `${ORG_INDEX_PATH}/canvas`; // 現在使用されていない

export const COM_INDEX_PATH = "/communication";
export const COM_MEETING_PATH = `${COM_INDEX_PATH}/meeting`;

export const MYPAGE_INDEX_PATH = `/mypage`;
export const MYPAGE_PROFILE_PATH = `${MYPAGE_INDEX_PATH}/profile`;
export const MYPAGE_EMAIL_INFO_PATH = `${MYPAGE_INDEX_PATH}/email-address`;
export const MYPAGE_PASSWORD_PATH = `${MYPAGE_INDEX_PATH}/password`;
export const MYPAGE_GOOGLE_CALENDAR_LINK_PATH = `${MYPAGE_INDEX_PATH}/google-calendar-link`;
export const MYPAGE_WORKSPACE_PATH = `${MYPAGE_INDEX_PATH}/workspace`;
export const MYPAGE_PAYMENT_SETTING_PATH = `${MYPAGE_INDEX_PATH}/payment-setting`;
export const MYPAGE_PLAN_AND_INVOICE_PATH = `${MYPAGE_INDEX_PATH}/plan-and-invoice`;
export const MYPAGE_COMPANY_INFO_PATH = `${MYPAGE_INDEX_PATH}/company-info`;
export const MYPAGE_BILLING_INFO_PATH = `${MYPAGE_INDEX_PATH}/billing-info`;
export const MYPAGE_EXTERNAL_LINKAGE_PATH = `${MYPAGE_INDEX_PATH}/external-linkage`;

export const ANALYTICS_INDEX_PATH = "/analytics";
export const ANALYTICS_WORKSPACE_PATH = (id: string) => `${ANALYTICS_INDEX_PATH}/workspace/${id}`;
export const ANALYTICS_MANAGEMENT_PATH = (id: string) => `${ANALYTICS_INDEX_PATH}/management/${id}`;
export const ANALYTICS_GROUP_PATH = (id: string) => `${ANALYTICS_INDEX_PATH}/group/${id}`;
export const ANALYTICS_PROJECT_PATH = (id: string) => `${ANALYTICS_INDEX_PATH}/project/${id}`;
export const ANALYTICS_WORKSPACE_USER_PATH = (id: string) => `${ANALYTICS_INDEX_PATH}/workspace-user/${id}`;

// サポート・利用規約など、その他
export const SUPPORT_LINK_PATH =
  "https://docs.google.com/forms/d/e/1FAIpQLSfV9GV8jZ6utywkrNZdCZ04vYuqNCQiZb4XV4l64ywpVJ3HXQ/viewform";
export const TERMS_PATH = "/terms_240405_cl.pdf";
export const PRIVACY_POLICY_PATH = "https://suits.co.jp/privacy-policy/";
export const SUITUP_COMPANY_PATH = "https://suits.co.jp/";

// IMAGE系
const BACKGROUND_IMAGE_PATH = "/background";
export const HEADER_IN_BOARD_BG_PATH = BACKGROUND_IMAGE_PATH + "/brand-header-in-board.svg";
export const AUTH_LEFT_BG_PATH = BACKGROUND_IMAGE_PATH + "/auth-left-bg.png";
const ASSETS_PATH = "/assets";
export const NO_IMAGE_PATH = ASSETS_PATH + "/no_img.png";
