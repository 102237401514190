import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class AuthApi extends BaseApi {
  async postRenewToken(): Promise<
    APIPaths["/app/auth/renew-token"]["post"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.post("/app/auth/renew-token")).data;
  }
  async postVerificationCodeNew(
    requestBody: APIPaths["/app/auth/verification-code/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/auth/verification-code/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/auth/verification-code/new", requestBody)).data;
  }
  async postVerificationCodeConf(
    requestBody: APIPaths["/app/auth/verification-code/conf"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/auth/verification-code/conf"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/auth/verification-code/conf", requestBody)).data;
  }
  async postSignupWithEmail(
    requestBody: APIPaths["/app/auth/signup-with-email"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/auth/signup-with-email"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/auth/signup-with-email", requestBody)).data;
  }
  async postSignupWithSns(
    requestBody: APIPaths["/app/auth/signup-with-sns"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/auth/signup-with-sns"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/auth/signup-with-sns", requestBody)).data;
  }
  async postLoginWithEmail(
    requestBody: APIPaths["/app/auth/login-with-email"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/auth/login-with-email"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/auth/login-with-email", requestBody)).data;
  }
  async postLoginWithSns(
    requestBody: APIPaths["/app/auth/login-with-sns"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/auth/login-with-sns"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/auth/login-with-sns", requestBody)).data;
  }
  async postResetPassword(
    requestBody: APIPaths["/app/auth/reset-password"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/auth/reset-password"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/auth/reset-password", requestBody)).data;
  }
}
