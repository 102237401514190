import { APICompsSchema } from "@/apis/api-config";

type FirestorePathKeyType =
  | "userSignup"
  | "userLogin"
  | "appError"
  | "userRenewToken"
  | "primaryItemChange"
  | "secondaryItemChange"
  | "taskChange"
  | "workspaceUserChange"
  | "workspaceUserActive"
  | "meetingChange"
  | "groupChange"
  | "projectChange"
  | "workspaceChange"
  | "companyChange";

export const FIRESTORE_PATH_INFO: Record<FirestorePathKeyType, APICompsSchema["FirestoreDirectoryPathTypeEnum"]> = {
  userSignup: "env/{env}/user-signup",
  userLogin: "env/{env}/user-login",
  userRenewToken: "env/{env}/user-renew-token",
  appError: "env/{env}/app-error",
  primaryItemChange: "env/{env}/workspace/{workspace_id}/primary-item-change ",
  secondaryItemChange: "env/{env}/workspace/{workspace_id}/secondary-item-change ",
  taskChange: "env/{env}/workspace/{workspace_id}/task-change",
  workspaceUserChange: "env/{env}/workspace/{workspace_id}/workspace-user-change",
  workspaceUserActive: "env/{env}/workspace/{workspace_id}/workspace-user-active",
  meetingChange: "env/{env}/workspace/{workspace_id}/meeting-change",
  groupChange: "env/{env}/workspace/{workspace_id}/group-change",
  projectChange: "env/{env}/workspace/{workspace_id}/project-change",
  workspaceChange: "env/{env}/workspace/{workspace_id}/workspace-change",
  companyChange: "env/{env}/workspace/{workspace_id}/company-change",
};
