import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class CreditCardApi extends BaseApi {
  async getCreditCardListAll(
    parameters: APIPaths["/app/credit-card/list-all"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/credit-card/list-all"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/credit-card/list-all", {
        params: parameters,
      })
    ).data;
  }
  async putCreditCardDefaultById(
    parameters: APIPaths["/app/credit-card/{id}/default"]["put"]["parameters"]["path"],
  ): Promise<APIPaths["/app/credit-card/{id}/default"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/credit-card/${parameters.id}/default`)).data;
  }
  async deleteCreditCardById(
    parameters: APIPaths["/app/credit-card/{id}"]["delete"]["parameters"]["path"],
  ): Promise<APIPaths["/app/credit-card/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/app/credit-card/${parameters.id}`)).data;
  }
}
