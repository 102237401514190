import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class CompanyApi extends BaseApi {
  async getCompanyById(
    parameters: APIPaths["/app/company/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/app/company/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/app/company/${parameters.id}`)).data;
  }
  async postCompanyNew(
    requestBody: APIPaths["/app/company/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/company/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post(`/app/company/new`, requestBody)).data;
  }
  async patchCompanyById(
    parameters: APIPaths["/app/company/{id}"]["patch"]["parameters"]["path"],
    requestBody: APIPaths["/app/company/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/company/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/app/company/${parameters.id}`, requestBody)).data;
  }
  async postCompanyVerifyNewCode(
    requestBody: APIPaths["/app/company/verify-new-code"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/company/verify-new-code"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/company/verify-new-code", requestBody)).data;
  }
}
