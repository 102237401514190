import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class WorkspaceUserApi extends BaseApi {
  async getWorkspaceUserList(
    parameters: APIPaths["/app/workspace-user/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/workspace-user/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/workspace-user/list", {
        params: parameters,
      })
    ).data;
  }
  async postWorkspaceUserNew(
    requestBody: APIPaths["/app/workspace-user/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/workspace-user/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/workspace-user/new", requestBody)).data;
  }
  async getWorkspaceUserMe(
    parameters: APIPaths["/app/workspace-user/me"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/workspace-user/me"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/app/workspace-user/me", { params: parameters })).data;
  }
  async patchWorkspaceUserMe(
    requestBody: APIPaths["/app/workspace-user/me"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/workspace-user/me"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch("/app/workspace-user/me", requestBody)).data;
  }
  async getWorkspaceUserById(
    parameters: APIPaths["/app/workspace-user/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/app/workspace-user/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/app/workspace-user/${parameters.id}`)).data;
  }
  async patchWorkspaceUserById(
    parameters: APIPaths["/app/workspace-user/{id}"]["patch"]["parameters"]["path"],
    requestBody: APIPaths["/app/workspace-user/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/workspace-user/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/app/workspace-user/${parameters.id}`, requestBody)).data;
  }
  async deleteWorkspaceUserById(
    parameters: APIPaths["/app/workspace-user/{id}"]["delete"]["parameters"]["path"],
    requestBody: APIPaths["/app/workspace-user/{id}"]["delete"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/workspace-user/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.delete(`/app/workspace-user/${parameters.id}`, {
        data: requestBody,
      })
    ).data;
  }
  async putWorkspaceUserStatusById(
    parameters: APIPaths["/app/workspace-user/{id}/status"]["put"]["parameters"]["path"],
    requestBody: APIPaths["/app/workspace-user/{id}/status"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/workspace-user/{id}/status"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/workspace-user/${parameters.id}/status`, requestBody)).data;
  }
  async putWorkspaceUserPositionById(
    parameters: APIPaths["/app/workspace-user/{id}/position"]["put"]["parameters"]["path"],
    requestBody: APIPaths["/app/workspace-user/{id}/position"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/workspace-user/{id}/position"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/workspace-user/${parameters.id}/position`, requestBody)).data;
  }
  async putWorkspaceUserRestorationById(
    parameters: APIPaths["/app/workspace-user/{id}/restoration"]["put"]["parameters"]["path"],
    requestBody: APIPaths["/app/workspace-user/{id}/restoration"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/app/workspace-user/{id}/restoration"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/app/workspace-user/${parameters.id}/restoration`, requestBody)).data;
  }
}
