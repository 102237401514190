import { useRouter } from "next/router";
import React, { useEffect } from "react";

import { APICompsSchema } from "@/apis/api-config";
import { useAuth } from "@/custom-hooks/apis-common/use-auth";
import { isIncompletePath } from "@/utils/common-utils";
import { firestoreDB, getFirestoreColPath } from "@/utils/firebase-utils";
import { dateToUnixTimestamp } from "@/utils/time-utils";

import { AUTH_FORGOT_PASS_PAGE, AUTH_INDEX_PATH, AUTH_SIGNUP_PATH, MY_TASK_PATH } from "@/constants/app-path";
import { FIRESTORE_PATH_INFO } from "@/constants/firestore-path";
import { currentStaffAtom } from "@/global-states/api-atoms";
import { authTokenInfoAtom, fbUserCredentialAtom, initializingAtom } from "@/global-states/common-atoms";
import * as firestoreOp from "@firebase/firestore";
import { useAtom } from "jotai";

type Props = {
  children: React.ReactNode;
};

const AuthProvider: React.FC<Props> = (props) => {
  const router = useRouter();
  const { authSync, fbAuthSync } = useAuth();

  const [currentStaff] = useAtom(currentStaffAtom);
  const [authTokenInfo] = useAtom(authTokenInfoAtom);
  const [initializing, setInitializing] = useAtom(initializingAtom);
  const [fbUserCredential] = useAtom(fbUserCredentialAtom);

  useEffect(() => {
    (() => fbAuthSync(authTokenInfo?.fb_custom_token))();
  }, [authTokenInfo?.fb_custom_token]);

  useEffect(() => {
    if (!initializing) {
      return;
    }

    if (isIncompletePath(router.asPath)) {
      return;
    }

    (async () => {
      if (await authSync()) {
        if (
          !router.asPath ||
          router.asPath === "/" ||
          router.asPath === AUTH_INDEX_PATH ||
          router.asPath === AUTH_SIGNUP_PATH
        ) {
          await router.push(MY_TASK_PATH);
        }
      } else if (![AUTH_FORGOT_PASS_PAGE, AUTH_SIGNUP_PATH].includes(router.asPath)) {
        // 認証失敗時は、ログイン画面へ
        await router.push(AUTH_INDEX_PATH);
      }
      setTimeout(() => setInitializing(false), 1);
    })();
  }, [initializing, router.asPath]);

  useEffect(() => {
    const timeout = setTimeout(() => authSync({ silent: true }), 1000 * 60 * 15);
    return () => clearTimeout(timeout);
  }, [authTokenInfo?.access_token]);

  // workspaceUser 更新時に、currentStaff を更新する
  useEffect(() => {
    if (!fbUserCredential || !currentStaff) {
      return;
    }

    const { onSnapshot, query, collection, where } = firestoreOp;
    const now = dateToUnixTimestamp(new Date());
    const { workspaceUserChange } = FIRESTORE_PATH_INFO;

    const myCollection = collection(firestoreDB, getFirestoreColPath(workspaceUserChange, currentStaff.workspace_id));
    const unsubscribe = onSnapshot(
      query(myCollection, where("created_at", ">", now)),
      async (snapshot) => {
        for (const change of snapshot.docChanges()) {
          const changeObj = change.doc.data() as APICompsSchema["FirestoreWorkspaceUserChangeObject"];
          if (changeObj.workspace_user_id === currentStaff.id) {
            const result = await authSync();
            if (!result) {
              await router.push(AUTH_INDEX_PATH);
            }
            break;
          }
        }
      },
      (error) => {
        console.error(`failed onSnapshot(${myCollection}). `, error);
      },
    );
    return () => unsubscribe();
  }, [fbUserCredential]);

  return <>{props.children}</>;
};

export default AuthProvider;
