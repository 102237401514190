import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class SecondaryItemApi extends BaseApi {
  async getSecondaryItemList(
    parameters: APIPaths["/app/secondary-item/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/secondary-item/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/secondary-item/list", {
        params: parameters,
      })
    ).data;
  }

  async deleteSecondaryItemList(
    requestBody: APIPaths["/app/secondary-item/list"]["delete"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/secondary-item/list"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete("/app/secondary-item/list", { data: requestBody })).data;
  }

  async putSecondaryItemListRestoration(
    requestBody: APIPaths["/app/secondary-item/list/restoration"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/app/secondary-item/list/restoration"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put("/app/secondary-item/list/restoration", requestBody)).data;
  }

  async postSecondaryItemNew(
    requestBody: APIPaths["/app/secondary-item/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/secondary-item/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/secondary-item/new", requestBody)).data;
  }

  async getSecondaryItemById(
    parameters: APIPaths["/app/secondary-item/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/app/secondary-item/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/app/secondary-item/${parameters.id}`)).data;
  }

  async patchSecondaryItemById(
    parameters: APIPaths["/app/secondary-item/{id}"]["patch"]["parameters"]["path"],
    requestBody: APIPaths["/app/secondary-item/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/secondary-item/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/app/secondary-item/${parameters.id}`, requestBody)).data;
  }

  async deleteSecondaryItemById(
    parameters: APIPaths["/app/secondary-item/{id}"]["delete"]["parameters"]["path"],
    requestBody?: APIPaths["/app/secondary-item/{id}"]["delete"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/secondary-item/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/app/secondary-item/${parameters.id}`, { data: requestBody })).data;
  }

  async postSecondaryItemDuplicate(
    parameters: APIPaths["/app/secondary-item/{id}/duplicate"]["post"]["parameters"]["path"],
    requestBody: APIPaths["/app/secondary-item/{id}/duplicate"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/app/secondary-item/{id}/duplicate"]["post"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.post(`/app/secondary-item/${parameters.id}/duplicate`, requestBody)).data;
  }

  async putSecondaryItemPosition(
    parameters: APIPaths["/app/secondary-item/{id}/position"]["put"]["parameters"]["path"],
    requestBody: APIPaths["/app/secondary-item/{id}/position"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/secondary-item/{id}/position"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/secondary-item/${parameters.id}/position`, requestBody)).data;
  }

  async putSecondaryItemRestorationById(
    parameters: APIPaths["/app/secondary-item/{id}/restoration"]["put"]["parameters"]["path"],
    requestBody?: APIPaths["/app/secondary-item/{id}/restoration"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/app/secondary-item/{id}/restoration"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/app/secondary-item/${parameters.id}/restoration`, requestBody)).data;
  }
}
