import { css } from "@emotion/react";

import { appColor } from "@/styles/app-colors";
import { appTypo } from "@/styles/app-typography";
import { BaseComponentStyled } from "@/styles/components";

import { AppFontSizeType } from "@/types/common-types";

const CommonStyled = Object.assign({}, BaseComponentStyled, {});

export const DividerWithTextIconStyled = Object.assign({}, CommonStyled, {
  dividerWithTextIconStyled: css`
    display: flex;
    align-items: center;
  `,
  dividerStyled: css`
    flex: 1;
    height: 1px;
    background-color: ${appColor.Border.gray};
  `,
  titleStyled: css`
    font-family: ${appTypo.font.kintoSans};
    color: ${appColor.Text.secondary};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
  `,
});

export const ArrowRightIconStyled = Object.assign({}, CommonStyled, {
  arrowRightIconStyled: css`
    fill: ${appColor.App.primary};
  `,
});

export const ImageCropUploaderStyled = Object.assign({}, CommonStyled, {
  cropSpaceStyled: css`
    width: 280px;
    height: 280px;
    display: flex;
    justify-content: center;
    flex-flow: column;
    border-radius: 10px;
    background-color: #f4f7fb;
    position: relative;
    & .crop-area {
      border: 3px solid #00a0ff;
    }
  `,
  titleStyled: css`
    font-family: ${appTypo.font.kintoSans};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.1em;
    color: ${appColor.Text.primary};
  `,
  avatarSizeStyled: (avatarSize: number) => css`
    width: ${avatarSize}px;
    height: ${avatarSize}px;
  `,
});

export const InitialScreenContainerStyled = Object.assign({}, CommonStyled, {
  containerStyled: css`
    z-index: -10;
    background-image: url("/background/initial-background.png");
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
  `,
});

export const AppQueryAlertErrorStyled = Object.assign({}, CommonStyled, {
  borderStyled: css`
    border: 1px solid ${appColor.Border.gray};
    background-color: ${appColor.Background.white};
  `,
});

export const UndoAppSnackbarMessageStyled = Object.assign({}, CommonStyled, {
  undoButtonStyled: css`
    font-family: ${appTypo.font.kintoSans};
    color: ${appColor.App.white};
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    border-color: ${appColor.App.white};
    padding: 2px 8px;
    &:hover {
      border-color: ${appColor.App.white};
      opacity: 0.7;
    }
  `,
});

export const DefaultSnackBarStyled = Object.assign({}, CommonStyled, {
  contentStyled: css`
    & .MuiSnackbarContent-root {
      background-color: ${appColor.Text.secondary};
      color: ${appColor.App.white};
      font-family: ${appTypo.font.kintoSans};
    }
  `,
});

export const DataGridBaseCellStyled = Object.assign({}, CommonStyled, {
  multilineTextStyled: (
    type: AppFontSizeType = "large",
    opts?: {
      readonly?: boolean;
      rows?: number;
      bold?: boolean;
      weight?: number;
    },
  ) => css`
    width: 100%;
    font-family: ${appTypo.font.kintoSans};
    font-style: normal;
    font-weight: ${opts?.bold ? "bold" : opts?.weight ? `${opts.weight}` : "normal"};
    font-size: ${type === "small" ? 12 : type === "medium" ? 13 : 14}px;
    color: ${opts?.readonly ? appColor.Text.ghost : appColor.Text.primary};
    line-height: 120%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${opts?.rows === undefined ? 3 : opts.rows};
    word-break: break-all;
  `,
  colorTextStyled: (
    type: AppFontSizeType = "large",
    opts?: {
      color?: string;
      bold?: boolean;
      weight?: number;
    },
  ) => css`
    width: 100%;
    font-family: ${appTypo.font.kintoSans};
    font-style: normal;
    font-weight: ${opts?.bold ? "bold" : opts?.weight ? `${opts.weight}` : "normal"};
    font-size: ${type === "small" ? 12 : type === "medium" ? 13 : 14}px;
    line-height: 120%;
    color: ${opts?.color ? opts?.color : appColor.Text.primary};
    overflow: hidden;
    text-overflow: ellipsis;
    // white-space: nowrap;
    word-break: break-all;
  `,
});

export const DataGridStringEditCellStyled = Object.assign({}, CommonStyled, {
  editPositionRelativeStyled: css`
    position: relative;
    align-self: flex-start;
  `,
  editPopperPositionStyled: css`
    & .MuiOutlinedInput-root {
      border-radius: 2px;
    }
  `,
  additionEditPopperPositionStyled: css`
    & .MuiOutlinedInput-root {
      border-radius: 2px;
    }
  `,
  adjustEditCellStyled: (width: number) => css`
    top: -1px;
    left: -1px;
    position: absolute;
    width: ${width}px;
    display: block;
  `,
  editTextareaAutosizeStyled: (width: number, minHeight: number, type: AppFontSizeType, additionFontSize?: number) => {
    let fontSize = 14 + (additionFontSize || 0);
    if (type === "small") {
      fontSize -= 2;
    } else if (type === "medium") {
      fontSize -= 1;
    }
    return css`
      width: ${width}px;
      min-height: ${minHeight - 4}px;
      max-height: 240px;
      resize: none;
      overflow-y: scroll !important;
      font-family: ${appTypo.font.kintoSans};
      font-style: normal;
      font-weight: normal;
      line-height: 120%;
      font-size: ${fontSize}px;
      color: ${appColor.Text.primary};
      padding-left: 6px;
      border: 2px solid ${appColor.Border.brand};
    `;
  },
});

export const DragIndicatorIconStyled = Object.assign({}, CommonStyled, {
  textCenterStyled: css`
    width: 100%;
    text-align: center;
  `,
});

export const NewLineTextWithHighlighterStyled = Object.assign({}, CommonStyled, {});

export const MuiOpenOrCloseButtonStyled = Object.assign({}, CommonStyled, {
  plusIconStyled: (color: string, bgColor: string) => {
    return css`
      background-color: ${bgColor};
      color: ${color};
      border-radius: 50%;
    `;
  },
  minusIconStyled: (color: string, bgColor: string) => {
    return css`
      background-color: ${bgColor};
      color: ${color};
      border-radius: 50%;
    `;
  },
});

export const CustomDatePickerActionBarStyled = Object.assign({}, CommonStyled, {
  titleStyled: css`
    font-family: ${appTypo.font.kintoSans};
    color: ${appColor.Text.primary};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  `,
  containerStyled: css`
    max-width: 320px;
  `,
  tableHeaderTitleStyled: css`
    font-family: ${appTypo.font.kintoSans};
    color: ${appColor.Text.primary};
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  `,
  tableContentsTitleStyled: css`
    font-family: ${appTypo.font.kintoSans};
    color: ${appColor.Text.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background-color: ${appColor.Background.white};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    span {
      line-height: 28px;
    }
    & .MuiCheckbox-root {
      padding: 4px;
    }
  `,
  heightStyled: css`
    height: 100%;
  `,
  borderRadiusStyled: css`
    border-radius: 6px;
    border-color: ${appColor.Border.headerBell};
    border-width: 1px;
    border-style: solid;
    background-color: ${appColor.Background.dialogHeader};
  `,
  borderRightStyled: css`
    border-right: 1px solid ${appColor.Border.headerBell};
  `,
  borderBottomStyled: css`
    border-bottom: 1px solid ${appColor.Border.headerBell};
  `,
});
