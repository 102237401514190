import { useRouter } from "next/router";
import React, { useEffect } from "react";

import { isIncompletePath } from "@/utils/common-utils";

import {
  GROUP_TASK_PATH,
  MGMT_TASK_PATH,
  MY_TASK_PATH,
  ORG_DEFINE_PATH,
  ORG_PROJECT_PATH,
  ORG_STAFF_PATH,
  PROJECT_TASK_PATH,
  PUBLIC_TASK_PATH,
} from "@/constants/app-path";
import { LocalForageKeys } from "@/constants/local-forage-keys";
import localforage from "localforage";

type Props = {
  children: React.ReactNode;
};

const LocalStorageProvider: React.FC<Props> = (props) => {
  const router = useRouter();
  const { asPath: path, query } = router;

  const taskPaths = [MY_TASK_PATH, GROUP_TASK_PATH, MGMT_TASK_PATH, PUBLIC_TASK_PATH, PROJECT_TASK_PATH];
  const orgPaths = [ORG_STAFF_PATH, ORG_DEFINE_PATH, ORG_PROJECT_PATH];

  useEffect(() => {
    if (isIncompletePath(router.asPath)) {
      return;
    }

    (async () => {
      if (taskPaths.some((p) => path.includes(p))) {
        await localforage.setItem(LocalForageKeys.task.prevPage, path);
      } else if (orgPaths.some((p) => path.includes(p))) {
        await localforage.setItem(LocalForageKeys.org.prevPage, path);
      }
    })();
  }, [path, query]);

  return <>{props.children}</>;
};

export default LocalStorageProvider;
