import { useRouter } from "next/router";
import { useCallback } from "react";

import { useAppSnackbar } from "@/custom-hooks/use-app-snackbar";
import { isIncompletePath } from "@/utils/common-utils";

import { LocalForageKeys } from "@/constants/local-forage-keys";
import { currentStaffAtom, currentUserAtom } from "@/global-states/api-atoms";
import { authTokenInfoAtom, loadingAtom } from "@/global-states/common-atoms";
import { useSetAtom } from "jotai";
import localforage from "localforage";

export const useApiConfig = () => {
  const router = useRouter();
  const { setAppSnackbar } = useAppSnackbar();

  const setLoading = useSetAtom(loadingAtom);
  const setCurrentUser = useSetAtom(currentUserAtom);
  const setCurrentStaff = useSetAtom(currentStaffAtom);
  const setAuthTokenInfo = useSetAtom(authTokenInfoAtom);

  const resetAuth = useCallback(async () => {
    if (isIncompletePath(router.asPath)) {
      return;
    }
    setCurrentUser(null);
    setCurrentStaff(null);
    setAuthTokenInfo(undefined);
    setLoading(false);
    await localforage.removeItem(LocalForageKeys.auth.AccessToken);
    await localforage.removeItem(LocalForageKeys.auth.RefreshToken);
  }, [router.asPath]);

  const catchApiError = useCallback((err: any) => {
    console.error(err);
    const baseMessage = "一時的なエラーが発生しました。しばらくしてから再度お試しください。";
    const devMessage = err?.response?.data?.dev_message || err?.message || "";
    const message = err?.response?.data?.message || `${baseMessage} ${devMessage ? `[${devMessage}]` : ""}`;
    setAppSnackbar(message, { error: true });
  }, []);

  const getRefreshToken = useCallback(async (): Promise<string> => {
    const refreshToken = await localforage.getItem<string>(LocalForageKeys.auth.RefreshToken);
    if (typeof refreshToken === "string") {
      return refreshToken;
    } else {
      await resetAuth();
      return "";
    }
  }, []);

  return {
    resetAuth: resetAuth,
    catchApiError: catchApiError,
    getRefreshToken: getRefreshToken,
  };
};
