import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class WorkspaceApi extends BaseApi {
  async postWorkspaceNew(
    requestBody: APIPaths["/app/workspace/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/workspace/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/workspace/new", requestBody)).data;
  }

  async getWorkspaceById(
    parameters: APIPaths["/app/workspace/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/app/workspace/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/app/workspace/${parameters.id}`)).data;
  }

  async patchWorkspaceById(
    parameters: APIPaths["/app/workspace/{id}"]["patch"]["parameters"]["path"],
    requestBody: APIPaths["/app/workspace/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/workspace/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/app/workspace/${parameters.id}`, requestBody)).data;
  }

  async putWorkspaceOfficialPositionById(
    parameters: APIPaths["/app/workspace/{id}/official-position"]["put"]["parameters"]["path"],
    requestBody: APIPaths["/app/workspace/{id}/official-position"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/app/workspace/{id}/official-position"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/app/workspace/${parameters.id}/official-position`, requestBody)).data;
  }

  async putWorkspaceOfficialPositionsById(
    parameters: APIPaths["/app/workspace/{id}/official-positions"]["put"]["parameters"]["path"],
    requestBody: APIPaths["/app/workspace/{id}/official-positions"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/app/workspace/{id}/official-positions"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/app/workspace/${parameters.id}/official-positions`, requestBody)).data;
  }

  async postWorkspaceRestoreById(
    { id }: APIPaths["/app/workspace/{id}/restore"]["post"]["parameters"]["path"],
    requestBody: APIPaths["/app/workspace/{id}/restore"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/workspace/{id}/restore"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post(`/app/workspace/${id}/restore`, requestBody)).data;
  }

  async putWorkspaceGroupTypesById(
    parameters: APIPaths["/app/workspace/{id}/group-types"]["put"]["parameters"]["path"],
    requestBody: APIPaths["/app/workspace/{id}/group-types"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/workspace/{id}/group-types"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/workspace/${parameters.id}/group-types`, requestBody)).data;
  }

  async postWorkspaceVerifyNewCode(
    requestBody: APIPaths["/app/workspace/verify-new-code"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/workspace/verify-new-code"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/workspace/verify-new-code", requestBody)).data;
  }

  async postWorkspaceSyncPaymentById({
    id,
  }: APIPaths["/app/workspace/{id}/sync-payment"]["post"]["parameters"]["path"]): Promise<
    APIPaths["/app/workspace/{id}/sync-payment"]["post"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.post(`/app/workspace/${id}/sync-payment`)).data;
  }

  async getWorkspacePaymentClientSecretById({
    id,
  }: APIPaths["/app/workspace/{id}/payment-client-secret"]["get"]["parameters"]["path"]): Promise<
    APIPaths["/app/workspace/{id}/payment-client-secret"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get(`/app/workspace/${id}/payment-client-secret`)).data;
  }

  async putWorkspaceDisableById({
    id,
  }: APIPaths["/app/workspace/{id}/disable"]["put"]["parameters"]["path"]): Promise<
    APIPaths["/app/workspace/{id}/disable"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/app/workspace/${id}/disable`)).data;
  }
}
