import { APIPaths } from "@/apis/api-config";
import BaseApi from "@/apis/base-api";

export class SecondaryItemTplApi extends BaseApi {
  async getSecondaryItemTplList(
    queryParameters: APIPaths["/app/secondary-item-tpl/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/secondary-item-tpl/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/app/secondary-item-tpl/list", { params: queryParameters })).data;
  }

  async getSecondaryItemTplById(
    pathParameters: APIPaths["/app/secondary-item-tpl/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/app/secondary-item-tpl/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/app/secondary-item-tpl/${pathParameters.id}`)).data;
  }

  async postUseSecondaryItemTpl(
    requestBody: APIPaths["/app/secondary-item-tpl/use"]["post"]["requestBody"]["content"]["application/json"],
  ) {
    return (await this.post("/app/secondary-item-tpl/use", requestBody)).data;
  }
}
