import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class ProjectApi extends BaseApi {
  async getProjectList(
    parameters: APIPaths["/app/project/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/project/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/project/list", {
        params: parameters,
      })
    ).data;
  }
  async getProjectIdNameList(
    parameters: APIPaths["/app/project/id-name/list-all"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/project/id-name/list-all"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/project/list", {
        params: parameters,
      })
    ).data;
  }
  async postProjectNew(
    requestBody: APIPaths["/app/project/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/project/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/project/new", requestBody)).data;
  }
  async getProjectById(
    parameters: APIPaths["/app/project/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/app/project/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/app/project/${parameters.id}`)).data;
  }
  async patchProjectById(
    parameters: APIPaths["/app/project/{id}"]["patch"]["parameters"]["path"],
    requestBody: APIPaths["/app/project/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/project/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/app/project/${parameters.id}`, requestBody)).data;
  }
  async deleteProjectById(
    parameters: APIPaths["/app/project/{id}"]["delete"]["parameters"]["path"],
    requestBody: APIPaths["/app/project/{id}"]["delete"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/project/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.delete(`/app/project/${parameters.id}`, {
        data: requestBody,
      })
    ).data;
  }
  async putProjectPositionById(
    parameters: APIPaths["/app/project/{id}/position"]["put"]["parameters"]["path"],
    requestBody: APIPaths["/app/project/{id}/position"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/project/{id}/position"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/project/${parameters.id}/position`, requestBody)).data;
  }
  async putProjectRestorationById(
    parameters: APIPaths["/app/project/{id}/restoration"]["put"]["parameters"]["path"],
    requestBody: APIPaths["/app/project/{id}/restoration"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/project/{id}/restoration"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/project/${parameters.id}/restoration`, requestBody)).data;
  }
}
