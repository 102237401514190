import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class WorkspaceExServiceApi extends BaseApi {
  async getWorkspaceExServiceListAll(
    parameters: APIPaths["/app/workspace-ex-service/list-all"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/workspace-ex-service/list-all"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/app/workspace-ex-service/list-all", { params: parameters })).data;
  }

  async postWorkspaceExServiceNew(
    requestBody: APIPaths["/app/workspace-ex-service/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/workspace-ex-service/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/workspace-ex-service/new", requestBody)).data;
  }

  async getWorkspaceExServiceById(
    parameters: APIPaths["/app/workspace-ex-service/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/app/workspace-ex-service/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/app/workspace-ex-service/${parameters.id}`)).data;
  }

  async deleteWorkspaceExServiceById(
    parameters: APIPaths["/app/workspace-ex-service/{id}"]["delete"]["parameters"]["path"],
  ): Promise<APIPaths["/app/workspace-ex-service/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/app/workspace-ex-service/${parameters.id}`)).data;
  }
}
