import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class BusinessCategoryApi extends BaseApi {
  async getBusinessCategoryList(): Promise<
    APIPaths["/app/business-category/list-all"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get("/app/business-category/list-all")).data;
  }
}
