import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class MeetingApi extends BaseApi {
  async getMeetingList(
    parameters: APIPaths["/app/meeting/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/meeting/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/meeting/list", {
        params: parameters,
      })
    ).data;
  }
  async postMeetingNew(
    requestBody: APIPaths["/app/meeting/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/meeting/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/meeting/new", requestBody)).data;
  }
  async getMeetingById(
    parameters: APIPaths["/app/meeting/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/app/meeting/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/app/meeting/${parameters.id}`)).data;
  }
  async patchMeetingById(
    parameters: APIPaths["/app/meeting/{id}"]["patch"]["parameters"]["path"],
    requestBody: APIPaths["/app/meeting/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/meeting/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/app/meeting/${parameters.id}`, requestBody)).data;
  }
  async deleteMeetingById(
    parameters: APIPaths["/app/meeting/{id}"]["delete"]["parameters"]["path"],
    requestBody: APIPaths["/app/meeting/{id}"]["delete"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/meeting/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.delete(`/app/meeting/${parameters.id}`, {
        data: requestBody,
      })
    ).data;
  }
  async putMeetingPeriodicById(
    parameters: APIPaths["/app/meeting/{id}/periodic"]["put"]["parameters"]["path"],
    requestBody: APIPaths["/app/meeting/{id}/periodic"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/meeting/{id}/periodic"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/meeting/${parameters.id}/periodic`, requestBody)).data;
  }
  async postMeetingPositionById(
    parameters: APIPaths["/app/meeting/{id}/position"]["put"]["parameters"]["path"],
    requestBody: APIPaths["/app/meeting/{id}/position"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/meeting/{id}/position"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/meeting/${parameters.id}/position`, requestBody)).data;
  }
  async putMeetingRestorationById(
    parameters: APIPaths["/app/meeting/{id}/restoration"]["put"]["parameters"]["path"],
    requestBody: APIPaths["/app/meeting/{id}/restoration"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/meeting/{id}/restoration"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/meeting/${parameters.id}/restoration`, requestBody)).data;
  }
  async getMeetingRoomNameList(
    parameters: APIPaths["/app/meeting/room-name/list-all"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/meeting/room-name/list-all"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/app/meeting/room-name/list-all", { params: parameters })).data;
  }
}
