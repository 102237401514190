import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class PlanContractApi extends BaseApi {
  async getPlanContractListAll(
    parameters: APIPaths["/app/plan-contract/list-all"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/plan-contract/list-all"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/plan-contract/list-all", {
        params: parameters,
      })
    ).data;
  }

  async postPlanContractNew(
    requestBody: APIPaths["/app/plan-contract/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/plan-contract/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/plan-contract/new", requestBody)).data;
  }

  async postPlanContractChange(
    requestBody: APIPaths["/app/plan-contract/change"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/plan-contract/change"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/plan-contract/change", requestBody)).data;
  }

  async putPlanContractCancelById(
    parameters: APIPaths["/app/plan-contract/{id}/cancel"]["put"]["parameters"]["path"],
  ): Promise<APIPaths["/app/plan-contract/{id}/cancel"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/plan-contract/${parameters.id}/cancel`)).data;
  }
}
