import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class UserApi extends BaseApi {
  async getUserMe(): Promise<APIPaths["/app/user/me"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/app/user/me")).data;
  }
  async postUserMeEmail(
    requestBody: APIPaths["/app/user/me/email"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/user/me/email"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post(`/app/user/me/email`, requestBody)).data;
  }
  async deleteUserMeEmail(
    requestBody: APIPaths["/app/user/me/email"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/user/me/email"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.delete(`/app/user/me/email`, {
        data: requestBody,
      })
    ).data;
  }
  async postUserMePassword(
    requestBody: APIPaths["/app/user/me/password"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/user/me/password"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post(`/app/user/me/password`, requestBody)).data;
  }
  async postUserMeLinkSns(
    requestBody: APIPaths["/app/user/me/link-sns"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/user/me/link-sns"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post(`/app/user/me/link-sns`, requestBody)).data;
  }
  async putUserMeAgreeTerms(): Promise<
    APIPaths["/app/user/me/agree-terms"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/app/user/me/agree-terms`)).data;
  }
}
