import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class ImageApi extends BaseApi {
  async registerImageByLambdaEdge(
    requestBody: APIPaths["/app/image/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/image/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/image/new", requestBody)).data;
  }
}
