import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class GroupApi extends BaseApi {
  async getGroupList(
    parameters: APIPaths["/app/group/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/group/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/group/list", {
        params: parameters,
      })
    ).data;
  }
  async getTopdownGroupListAll(
    parameters: APIPaths["/app/group/topdown/list-all"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/group/topdown/list-all"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/group/topdown/list-all", {
        params: parameters,
      })
    ).data;
  }
  async getGroupIdNameList(
    parameters: APIPaths["/app/group/id-name/list-all"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/group/id-name/list-all"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/group/list", {
        params: parameters,
      })
    ).data;
  }
  async postGroupNew(
    requestBody: APIPaths["/app/group/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/group/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/group/new", requestBody)).data;
  }
  async getGroupById(
    parameters: APIPaths["/app/group/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/app/group/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/app/group/${parameters.id}`)).data;
  }
  async patchGroupById(
    parameters: APIPaths["/app/group/{id}"]["patch"]["parameters"]["path"],
    requestBody: APIPaths["/app/group/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/group/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/app/group/${parameters.id}`, requestBody)).data;
  }
  async deleteGroupById(
    parameters: APIPaths["/app/group/{id}"]["delete"]["parameters"]["path"],
    requestBody: APIPaths["/app/group/{id}"]["delete"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/group/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.delete(`/app/group/${parameters.id}`, {
        data: requestBody,
      })
    ).data;
  }
  async putGroupRestorationById(
    parameters: APIPaths["/app/group/{id}/restoration"]["put"]["parameters"]["path"],
    requestBody: APIPaths["/app/group/{id}/restoration"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/group/{id}/restoration"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/group/${parameters.id}/restoration`, requestBody)).data;
  }
}
