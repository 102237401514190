export const LocalForageKeys = {
  app: {
    currentWorkspaceId: "80329CB67928B97D1FA857790F70F0FC",
  },
  auth: {
    AccessToken: "CFCFF7782C565187C3C1F15300289B3A",
    RefreshToken: "0F2DEBD3E8602D00A1C1C2B581E66CC4",
  },
  task: {
    prevPage: "EA5344C2BF5D6462D13E3A4A04101040",
    subMenu: {
      me: "A2698BBB6DFC36D6035078C1C9748519",
      group: "8A578E9D9F0D44669976839F4683459F",
      mgmt: "46609D78716D47A4B1E5F9154791D776",
      public: "A45EFEF09E0BA62CAB157C8F68562B15",
      project: "8904BDFC5A5F6A824566B3BCB1292485",
    },
  },
  org: {
    prevPage: "5E7279882D7E10F2895F694B642AC956",
    subMenu: {
      staff: "8A578E9D9F0D44669976839F4683459F",
      define: "5F1E211B5DED8BFD9E23A70D40999B2D",
      project: "3DDB363F8CC444A10ABF0722F597FB2F",
    },
  },
  firstTemplateHint: {
    key: "06FDE2ABEA8B921BD3C453E75C273E6E",
    read: "read",
  },
} as const;
