import { useMemo } from "react";

import { AuthApi } from "@/apis/connect/auth-api";
import { BusinessCategoryApi } from "@/apis/connect/business-category-api";
import { CompanyApi } from "@/apis/connect/company-api";
import { CreditCardApi } from "@/apis/connect/credit-card-api";
import { GroupApi } from "@/apis/connect/group-api";
import { ImageApi } from "@/apis/connect/image-api";
import { InvoiceApi } from "@/apis/connect/invoice-api";
import { MeetingApi } from "@/apis/connect/meeting-api";
import { PlanContractApi } from "@/apis/connect/plan-contract-api";
import { PrimaryItemApi } from "@/apis/connect/primary-item-api";
import { PrimaryItemTplApi } from "@/apis/connect/primary-item-tpl-api";
import { ProjectApi } from "@/apis/connect/project-api";
import { SecondaryItemApi } from "@/apis/connect/secondary-item-api";
import { SecondaryItemTplApi } from "@/apis/connect/secondary-item-tpl-api";
import { TaskApi } from "@/apis/connect/task-api";
import { TaskTplApi } from "@/apis/connect/task-tpl-api";
import { UserApi } from "@/apis/connect/user-api";
import { WorkspaceApi } from "@/apis/connect/workspace-api";
import { WorkspaceExServiceApi } from "@/apis/connect/workspace-ex-service-api";
import { WorkspaceUserApi } from "@/apis/connect/workspace-user-api";

import { authTokenInfoAtom } from "@/global-states/common-atoms";
import { useAtom } from "jotai";

export const useApiConnector = () => {
  const [authTokenInfo] = useAtom(authTokenInfoAtom);

  const appAuthApi = useMemo(() => new AuthApi(authTokenInfo?.access_token), [authTokenInfo]);
  const appBusinessTypeApi = useMemo(() => new BusinessCategoryApi(authTokenInfo?.access_token), [authTokenInfo]);
  const appCompanyApi = useMemo(() => new CompanyApi(authTokenInfo?.access_token), [authTokenInfo]);
  const appCreditCardApi = useMemo(() => new CreditCardApi(authTokenInfo?.access_token), [authTokenInfo]);
  const appGroupApi = useMemo(() => new GroupApi(authTokenInfo?.access_token), [authTokenInfo]);
  const appImageApi = useMemo(() => new ImageApi(authTokenInfo?.access_token), [authTokenInfo]);
  const appInvoiceApi = useMemo(() => new InvoiceApi(authTokenInfo?.access_token), [authTokenInfo]);
  const appMeetingApi = useMemo(() => new MeetingApi(authTokenInfo?.access_token), [authTokenInfo]);
  const appPlanContractApi = useMemo(() => new PlanContractApi(authTokenInfo?.access_token), [authTokenInfo]);
  const appPrimaryItemApi = useMemo(() => new PrimaryItemApi(authTokenInfo?.access_token), [authTokenInfo]);
  const appPrimaryItemTplApi = useMemo(() => new PrimaryItemTplApi(authTokenInfo?.access_token), [authTokenInfo]);
  const appProjectApi = useMemo(() => new ProjectApi(authTokenInfo?.access_token), [authTokenInfo]);
  const appSecondaryItemApi = useMemo(() => new SecondaryItemApi(authTokenInfo?.access_token), [authTokenInfo]);
  const appSecondaryItemTplApi = useMemo(() => new SecondaryItemTplApi(authTokenInfo?.access_token), [authTokenInfo]);
  const appTaskApi = useMemo(() => new TaskApi(authTokenInfo?.access_token), [authTokenInfo]);
  const appTaskTplApi = useMemo(() => new TaskTplApi(authTokenInfo?.access_token), [authTokenInfo]);
  const appUserApi = useMemo(() => new UserApi(authTokenInfo?.access_token), [authTokenInfo]);
  const appWorkspaceApi = useMemo(() => new WorkspaceApi(authTokenInfo?.access_token), [authTokenInfo]);
  const appWorkspaceExServiceApi = useMemo(
    () => new WorkspaceExServiceApi(authTokenInfo?.access_token),
    [authTokenInfo],
  );
  const appWorkspaceUserApi = useMemo(() => new WorkspaceUserApi(authTokenInfo?.access_token), [authTokenInfo]);

  return {
    appAuthApi,
    appBusinessTypeApi,
    appCompanyApi,
    appCreditCardApi,
    appGroupApi,
    appImageApi,
    appInvoiceApi,
    appMeetingApi,
    appPlanContractApi,
    appPrimaryItemApi,
    appPrimaryItemTplApi,
    appProjectApi,
    appSecondaryItemApi,
    appSecondaryItemTplApi,
    appTaskApi,
    appTaskTplApi,
    appUserApi,
    appWorkspaceApi,
    appWorkspaceExServiceApi,
    appWorkspaceUserApi,
  };
};
