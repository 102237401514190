import { APICompsSchema } from "@/apis/api-config";

import { AppFontSizeType, CurrentCellState, HighlightActiveState } from "@/types/common-types";
import { UserCredential } from "@firebase/auth";
import { atom } from "jotai";

export const initializingAtom = atom<boolean>(true);
export const loadingAtom = atom<boolean>(false);
export const showSavingAtom = atom<boolean>(false);
export const dataGridLoadingAtom = atom<boolean>(false);
export const workspaceSwitchingAtom = atom<boolean>(false);
export const upOrDownKeyLongPressingAtom = atom<boolean>(false);
export const hiddenRowLoadingAtom = atom<boolean>(false);
export const isProcessingOnEnterAfterAtom = atom<boolean>(false);

export const authTokenInfoAtom = atom<APICompsSchema["AuthTokenResponse"] | undefined>(undefined);
export const searchTextAtom = atom<string>("");
export const highlightActiveStateAtom = atom<HighlightActiveState | undefined>(undefined);
export const appFontTypeAtom = atom<AppFontSizeType>("large");
export const imeInputCellStateAtom = atom<CurrentCellState | undefined>(undefined);

export const fbUserCredentialAtom = atom<UserCredential | undefined>(undefined);

// テーブル左端のIndex + Reorderの列の幅のAtom
export const tableLeftIndexWidthAtom = atom<number>(46);

// 現在のアクティブユーザーを保持するAtom
export const currentActiveWorkspaceUsersAtom = atom<APICompsSchema["FirestoreWorkspaceUserActiveObject"][]>([]);

// 会議モード・編集モードを分けるためのAtom
export const isSuitUpDisplayModeAtom = atom<"meeting" | "edit">("meeting");

// タスクページ・タブの表示順のAtom
export const taskTabCustomObjectAtom = atom<{
  object: APICompsSchema["FirestoreTaskTabCustomObject"] | null;
  id: string | null;
}>({
  object: null,
  id: null,
});
// タスクテンプレート用
export const inputTextDataGridCellAtom = atom<string>("");

// ログイン中のユーザに紐づくワークスペース全ての、タスクページ・タブの表示順のAtom
export const taskTabCustomObjectsAtom = atom<
  {
    object: APICompsSchema["FirestoreTaskTabCustomObject"] | null;
    workspaceId: string | null;
  }[]
>([]);

export const addWorkspaceDialogAtom = atom<{
  open: boolean;
}>({
  open: false,
});

export const isHiddenTrialHeaderAtom = atom<boolean>(true);
