import { css } from "@emotion/react";

import { appColor } from "@/styles/app-colors";
import { appTypo } from "@/styles/app-typography";

import { AppFontSizeType } from "@/types/common-types";

// すべてコンポーネントで利用できるStyled
export const BaseComponentStyled = {
  displayFlexStyled: css`
    display: flex;
  `,
  displayNoneStyled: css`
    display: none;
  `,
  fullWidthStyled: css`
    width: 100%;
  `,
  fullHeightAndWidthStyled: css`
    height: 100%;
    width: 100%;
  `,
  cursorPointerStyled: css`
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  `,
  hoverOpacityStyled: css`
    &:hover {
      opacity: 0.7;
    }
  `,
  positionRelativeStyled: css`
    position: relative;
  `,
  nextLinkStyled: css`
    cursor: pointer;
    color: ${appColor.Accent.blue};
    &:hover {
      opacity: 0.8;
      color: ${appColor.Accent.blue};
    }
  `,
  padding: (pt: number, pr: number, pb: number, pl: number) => css`
    padding-top: ${pt * 8}px;
    padding-right: ${pr * 8}px;
    padding-bottom: ${pb * 8}px;
    padding-left: ${pl * 8}px;
  `,
  gap: (px: number) => css`
    gap: ${px}px;
  `,
  iconHalfRotateStyled: css`
    transform: rotate(90deg);
  `,
  iconRotateStyled: (open: boolean) => css`
    transition: all 0.3s 0s ease;
    transform: ${open ? "rotate(180deg)" : "rotate(0deg)"};
  `,
  pageTitleStyled: css`
    display: flex;
    font-family: ${appTypo.font.kintoSans};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: ${appColor.Text.primary};
  `,
  grayPageTitleStyled: css`
    display: flex;
    font-family: ${appTypo.font.kintoSans};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: ${appColor.Text.secondary};
  `,
  formLabelStyled: css`
    font-family: ${appTypo.font.kintoSans};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: ${appColor.Text.primary};
    span {
      color: ${appColor.Accent.red};
      padding-left: 2px;
    }
  `,
  formSubLabelStyled: css`
    font-family: ${appTypo.font.kintoSans};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: ${appColor.Text.secondary};
  `,
  baseTextStyled: (
    type: AppFontSizeType = "large",
    opts?: {
      readonly?: boolean;
      color?: string;
      bold?: boolean;
      weight?: number;
      additionFontSize?: number;
      selectNone?: boolean;
    },
  ) => {
    let fontSize = 14 + (opts?.additionFontSize || 0);
    if (type === "small") {
      fontSize -= 2;
    } else if (type === "medium") {
      fontSize -= 1;
    }
    let color: string = opts?.readonly ? appColor.Text.ghost : appColor.Text.primary;
    if (opts?.color) {
      color = opts.color;
    }
    return css`
      font-family: ${appTypo.font.kintoSans};
      font-style: normal;
      font-weight: ${opts?.bold ? "bold" : opts?.weight ? `${opts.weight}` : "normal"};
      font-size: ${fontSize}px;
      line-height: 120%;
      color: ${color};
      overflow: hidden;
      text-overflow: ellipsis;
      // white-space: nowrap;
      word-break: break-all;
      ${opts?.selectNone ? "user-select: none;" : ""}
    `;
  },
  errorFormSubLabelStyled: css`
    font-family: ${appTypo.font.kintoSans};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: ${appColor.Text.error};
  `,
  // Context Menu関連
  contextMenuStyled: css`
    & .MuiList-root {
      padding: 4px 0;
      background-color: ${appColor.App.white};
    }
    & .MuiListItemIcon-root {
      min-width: 28px !important;
    }
    & .MuiTypography-root {
      font-family: ${appTypo.font.kintoSans};
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: ${appColor.Text.primary};
    }
    & .MuiMenuItem-root {
      &:hover {
        background-color: ${appColor.Background.tertiary};
      }
    }
  `,
  contextMenuItemStyled: (color: string) => css`
    cursor: pointer;
    font-family: ${appTypo.font.kintoSans};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.1px;
    color: ${color};
  `,
  // dialog 関連（共通）
  dialogTitleStyled: css`
    font-family: ${appTypo.font.kintoSans};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.1em;
    color: ${appColor.Text.primary};
  `,
  dialogScrollBodyStyled: css`
    max-height: 500px;
    overflow-y: auto;
  `,
  dialogContentsStyled: css`
    font-family: ${appTypo.font.kintoSans};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: ${appColor.Text.secondary};
  `,
  dialogMenuTitleStyled: css`
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-family: ${appTypo.font.kintoSans};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: ${appColor.Text.primary};
    span {
      color: ${appColor.Accent.red};
      padding-left: 2px;
    }
  `,
};

export const LayoutStyled = Object.assign({}, BaseComponentStyled, {
  authContainer: css`
    background-color: ${appColor.Background.auth};
    height: 100vh;
  `,
  displayFlexBox: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  `,
  fixedHeader: css`
    z-index: 1000;
    position: fixed;
    top: 0;
    width: 100%;
  `,
});

export const FilterStyled = Object.assign({}, BaseComponentStyled, {
  menuContainerStyled: css`
    padding: 0;
  `,
  filterGridContainerStyled: (width: number) => css`
    width: ${width}px;
  `,
  dividerStyled: css`
    border-bottom: 1px solid ${appColor.Border.gray};
  `,
  clearBtnStyled: css`
    text-decoration: underline;
    color: #3491fe;
    font-size: 12px;
    padding: 0;
  `,
  checkBoxStyled: css`
    padding: 0 8px 0 4px;
  `,
  fontSizeStyled: (fontSize: number) => css`
    font-size: ${fontSize}px;
  `,
  labelContainerStyled: css``,
  textFieldStyled: css`
    & .MuiInputBase-input {
      padding: 4px 4px 4px 0;
      font-family: ${appTypo.font.kintoSans};
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: ${appColor.Text.primary};
      &:focus {
        padding: 4px 4px 4px 0;
      }
      border: none;
    }
    fieldset {
      border: none;
    }
    border: 1px solid ${appColor.Border.gray};
    border-radius: 50px;
  `,
  dateFieldStyled: css`
    & .MuiInputBase-input {
      padding: 4px 4px 4px 8px;
      font-family: ${appTypo.font.kintoSans};
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: ${appColor.Text.primary};
      &:focus {
        padding: 4px 4px 4px 0;
      }
      border: none;
    }
    fieldset {
      border: none;
    }
    border: 1px solid ${appColor.Border.gray};
    border-radius: 50px;
    margin: 0 4px;
  `,
  avatarIcon: css`
    margin-right: 8px;
    width: 24px;
    height: 24px;
  `,
  formControlLabelStyled: (fontSize: number) => css`
    & .MuiFormControlLabel-label {
      font-size: ${fontSize}px;
    }
    font-family: ${appTypo.font.kintoSans};
    font-style: normal;
    font-weight: 400;
    font-size: ${fontSize}px!important;
    line-height: ${fontSize}px!important;
    height: 30px;
    margin-right: 0;
    color: ${appColor.Text.primary};
  `,
});
